@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
}

body.light {
  background-color: #f3f4f6;
  color: #1f2937;
}

body.dark {
  background-color: #1f2937;
  color: #f3f4f6;
}